import React from "react"

import LayoutContent from "./layout-content.js"
import layoutBackgroundOrangeStyles from "../styles/layout-background-orange.module.scss"

const LayoutBackgroundBurrito = props => {
  return (
    <div className={layoutBackgroundOrangeStyles.container}>
      <div className={layoutBackgroundOrangeStyles.overlay}></div>
      <LayoutContent>{props.children}</LayoutContent>
    </div>
  )
}

export default LayoutBackgroundBurrito