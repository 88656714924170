import React from "react"

import sidesStyles from "../styles/sides.module.scss"
import { FormattedNumber } from "gatsby-plugin-intl"

const Sides = ({props}) =>  {
    return (
        <div className={sidesStyles.container}>
            <div className={sidesStyles.header}>
                Sides
            </div>
            <div className={sidesStyles.sides}>
                <ul className={sidesStyles.sidesList}>
                    {props.items.map(side => (
                        <li key={side.name} className={sidesStyles.sideListItem}>
                            <div className={sidesStyles.sideNameAndPrice}>
                                <div className={sidesStyles.sideName}>
                                    {side.name}
                                </div>
                                <div className={sidesStyles.sidePrice}>
                                    <FormattedNumber  
                                        value={side.price}  
                                        style= {`currency`} //"currency" 
                                        currency="EUR" 
                                        currencyDisplay= "symbol"
                                    />
                                </div>
                            </div>
                            {side.info && (
                                <div className={sidesStyles.sideInfo}>
                                    {side.info}
                                </div>
                            )}
                            {side.ingredients && (
                                <ul className={sidesStyles.sideIngredients}>
                                    {side.ingredients.map(ingredient => (
                                        <li key={ingredient.name} >
                                            {ingredient.name}
                                        </li>
                                    ))}
                                </ul>                                
                            )}
                        </li>
                    ))}
                </ul>   
            </div>
        </div>
    )
}

export default Sides