import React from "react"

import { FormattedNumber } from "gatsby-plugin-intl"

import dishStyles from "../styles/dish.module.scss"


const Dish = ({props}) => {
    return (
        <div className={dishStyles.container}>
            <div className={dishStyles.title}>
                {props.name}
            </div>
            <div className={dishStyles.ingredients}>
                <ul>
                    {props.ingredients.map(ingredient => (
                        <li key={ingredient.name} >
                            {ingredient.name}
                        </li>
                    ))}
    
                </ul>
            </div>
            <div className={dishStyles.prices}>
                {props.priceSmall &&
                    <div className={dishStyles.priceTag}>
                        <div className={dishStyles.sizeTag}>
                            Small       
                        </div>
                        <div className={dishStyles.price}>
                            <FormattedNumber  
                                value={props.priceSmall}  
                                style= {`currency`} //"currency" 
                                currency="EUR" 
                                currencyDisplay= "symbol"
                            />
                        </div>
                    </div>
                }
                {props.priceLarge && 
                    <div className={dishStyles.priceTag}>
                        <div className={dishStyles.sizeTag}>
                            Large
                        </div>
                        <div className={dishStyles.price}>
                            <FormattedNumber  
                                value={props.priceLarge}  
                                style={`currency`}
                                currency="EUR" 
                                currencyDisplay= "symbol"
                            />
                    </div>
                    </div>
                }
                {props.price && 
                    <div className={dishStyles.priceTag}>
                        <div className={dishStyles.price}>
                            <FormattedNumber  
                                value={props.price}  
                                style={`currency`}
                                currency="EUR" 
                                currencyDisplay= "symbol"
                            />
                    </div>
                    </div>
                }
            </div>            
        </div>
    )    
}

export default Dish