import React from "react"
import { graphql } from "gatsby"

import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import LayoutBackgroundOrange from "../components/layout-background-orange.js"
import Dish from "../components/dish.js"
import Sides from "../components/sides.js"
import foodStyles from "../styles/food.module.scss"

const FoodPage = ({data, intl}) => {
  // const intl = useIntl()
  console.log(intl)

  return (
    <LayoutBackgroundOrange>
      {/* Burritos */}
      <div className={foodStyles.container}>
        <div className={foodStyles.typeContainer}>
          <div className={foodStyles.banner}>
            <Img fluid={data.burritoBanner.childImageSharp.fluid} />
          </div>
          <div className={foodStyles.typeHeader}>
            Burritos
          </div>
          <ul className={foodStyles.typeList}>
            {data.takeshape.getBurritosList.items.map(burrito => (
              <li key={burrito.name} className={foodStyles.typeListItem}>
                <Dish props={burrito}/>
              </li>
            ))}
          </ul>
        </div>
        {/* Quesadillas */}
        <div className={foodStyles.typeContainer}>
          <div className={foodStyles.banner}>
            <Img fluid={data.quesadillaBanner.childImageSharp.fluid} />
          </div>  
          <div className={foodStyles.typeHeader}>
            Quesadillas
          </div>
          <ul className={foodStyles.typeList}>
            {data.takeshape.getQuesadillasList.items.map(burrito => (
              <li key={burrito.name} className={foodStyles.typeListItem}>
                <Dish props={burrito}/>
              </li>
            ))}
          </ul>
        </div>
        {/* Bowls */}
        <div className={foodStyles.typeContainer}>
          <div className={foodStyles.banner}>
            <Img fluid={data.bowlBanner.childImageSharp.fluid} />
          </div>  
          <div className={foodStyles.typeHeader}>
            Bowls
          </div>
          <ul className={foodStyles.typeList}>
            {data.takeshape.getBowlsList.items.map(burrito => (
              <li key={burrito.name} className={foodStyles.typeListItem}>
                <Dish props={burrito}/>
              </li>
            ))}
          </ul>
        </div>
        {/* Sides & Extras */}
        <div className={foodStyles.typeContainer}>
          <div className={foodStyles.banner}>
            <Img fluid={data.sideBanner.childImageSharp.fluid} />
          </div>  
          <div className={foodStyles.sidesAndExtras}>
              <div className={foodStyles.sides}>
                <Sides props={data.takeshape.getSidesList} />
              </div>  
              <div className={foodStyles.extras}>
                
              </div>
          </div>
        </div>

      </div>

    </LayoutBackgroundOrange>
  )
}

export const pageQuery = graphql`
  query MyQuery($language: String) {
    burritoBanner: file(relativePath: {eq: "food/HT_Banner_Burritos.png"}) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid  
        }
      }
    }
    quesadillaBanner: file(relativePath: {eq: "food/HT_Banner_Quesadillas.png"}) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid  
        }
      }
    }
    bowlBanner: file(relativePath: {eq: "food/HT_Banner_Bowls.png"}) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid  
        }
      }
    }
    sideBanner: file(relativePath: {eq: "food/HT_Banner_Sides.png"}) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid  
        }
      }
    }
    takeshape {
      getBurritosList(locale: $language, sort: {field: "level", order: "ASC"}) {
        items {
          name
          ingredients {
            name
          }
          priceLarge
          priceSmall
          vegetarian
        }
      }
      getQuesadillasList(locale: $language, sort: {field: "level", order: "ASC"}) {
        items {
          name
          priceLarge
          priceSmall
          vegetarian
          ingredients {
            name
          }
        }
      }
      getBowlsList(locale: $language, sort: {field: "level", order: "ASC"}) {
        items {
          name
          price
          vegetarian
          ingredients {
            name
          }
        }
      }   
      getSidesList(locale: $language, sort: {field: "level", order: "ASC"}) {
        items {
          info
          name
          price
          vegetarian
          ingredients {
            name
            diet
          }
        }
      }     
    }
  }
`

export default injectIntl(FoodPage)
